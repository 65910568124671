import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import { ContextProvider } from './Context/Context.jsx'
import "./styles/ollStyles.css"
import "./styles/main.css"
import "./assets/main.css"

window.ResizeObserver = undefined;
ReactDOM.createRoot(document.getElementById('root')).render(
    <ContextProvider>
        <App />
    </ContextProvider>
)
