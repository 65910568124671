import React, { useContext, useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import seorchIcon from "../../assets/img/svg/seorchIcon.svg";
import dropDownSelect from "../../assets/img/svg/down-arrow-select.svg";
import forAdults from "../../assets/img/svg/18+.svg";
import rus from "../../assets/img/svg/ru.svg";
import vpn from "../../assets/img/png/vpn.png";
import { useNavigate } from "react-router-dom";
import clock from "../../assets/img/svg/time.svg";
import freee from "../../assets/img/svg/free.svg";
import money from "../../assets/img/svg/flag.svg";
import { Context } from "../../Context/Context";
import Aos from "aos";
import "aos/dist/aos.css";
import http from "../../service/api";
import { Helmet } from "react-helmet-async";
export default function NeuralNetwork() {
  const {
    fitlername,
    searchtext,
    allSearchtype,
    seo,
    paidGlobal,
    trialGlobal,
    freeGlobal,
    categoryGlobal,
  } = useContext(Context);
  const [data, setData] = useState([]);
  const [zadacha, setZadacha] = useState([]);
  const [free, setFree] = useState(true);
  const [trail, setTrail] = useState(allSearchtype ? trialGlobal : false);
  const [count, setCount] = useState(1);
  const [paginate, setPaginate] = useState(true);
  const [paid, setPaid] = useState(allSearchtype ? paidGlobal : false);
  const [reklama2, setReklama2] = useState([]);
  const [button, setButto] = useState(allSearchtype ? fitlername : "");
  const [rotate, setRotate] = useState(false);
  const [refresh2, setRefresh2] = useState(false);
  const [filtertype, setFilertype] = useState("");
  const [search, setSearch] = useState(allSearchtype ? searchtext : "");
  const [zadachaid, setZadachaid] = useState(
    allSearchtype ? categoryGlobal : ""
  );
  const [freeid, setFreeid] = useState(allSearchtype ? freeGlobal : false);
  const [trailid, setTrailId] = useState(false);
  const [totalPage, setTotalPage] = useState("");
  const [activenum, setActiveNUm] = useState(1);
  const [refreshd, setRefreshd] = useState(false);
  const { refful } = useContext(Context);
  const navigate = useNavigate();
  const paidChange = () => {
    setPaid(!paid);
    setActiveNUm(1);
    setTrailId(!paid);
    setRefreshd(!refreshd);
  };
  useEffect(() => {
    getData();
  }, [refreshd, refful]);
  useEffect(() => {
    getZadacha();
    getReklama2();
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);
  const getData = () => {
    http
      .get(
        `/neauralnetwork/list/all/?title=${search}&zadacha=${zadachaid}&free=${
          freeid ? freeid : ""
        }&trial=${trail === true ? trail : " "}&limit=45&offset=${
          (activenum - 1) * 45
        }&tag=${button === 0 ? "" : button}&paid=${paid ? paid : ""}`
      )
      .then((res) => {
        setData(res.data.results);
        setTotalPage(Math.ceil(res.data.count / 45));
        setPaginate(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
    setActiveNUm(1);
    setRefreshd(!refreshd);
  };
  const getZadacha = () => {
    http
      .get("/neauralnetwork/list/zadacha/")
      .then((res) => {
        setZadacha(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSelect = (e) => {
    setActiveNUm(1);
    setZadachaid(e.target.value);
    setRefreshd(!refreshd);
  };
  const handleChacked = () => {
    setFree(!free);
    setActiveNUm(1);
    setFreeid(!freeid);
    setRefreshd(!refreshd);
  };
  const handeClick = (text) => {
    navigate(`/Neytroitem/${text}`);
  };
  const trailChange = () => {
    setTrail(!trail);
    setActiveNUm(1);
    setTrailId(!trailid);
    setRefreshd(!refreshd);
  };
  const getPopular = () => {
    http
      .get(
        `/neauralnetwork/list/popular/?title=${search}&zadacha=${zadachaid}&free=${
          freeid ? freeid : ""
        }&trial=${trail === true ? trail : " "}&limit=45&offset=${
          (activenum - 1) * 45
        }&tag=${button === 0 ? "" : button}&paid=${paid ? paid : ""}`
      )
      .then((res) => {
        setData(res.data);
        setPaginate(false);
        setCount(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReklama2 = () => {
    http
      .get("/neauralnetwork/adversiment/banner/")
      .then((res) => {
        setReklama2(res.data.results);
        setRefresh2(!refresh2);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const filtrType = (id) => {
    setButto(id);
    setActiveNUm(1);
    setRefreshd(!refreshd);
  };

  const getPageNumbers = (id) => {
    const pageNumbers = [];
    for (let i = 1; i <= id; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const changeActiveNum = (id) => {
    setActiveNUm(id);
    setRefreshd(!refreshd);
  };
  useEffect(() => {
    // Sayfanın yukarı kaydırılması
    window.scrollTo(0, 0);
  }, []);
  const handleMenu = (e, link) => {
    e.preventDefault(); // Prevent the default right-click context menu
    window.open(`https://ai-store.pro/Neytroitem/${link}`, `_${link}`); // Redirect to the URL
  };
  return (
    <>
      <Helmet>
        <title>{seo?.ceo_title_neural}</title>
        <meta name="description" content={seo?.ceo_description_neural} />
      </Helmet>
      <div className="page__Promty">
        <div className="rang">
          <Header />
        </div>
        <div id="rer" className="container ">
          <p className="navigation__strel">
            {" "}
            <span onClick={() => navigate("/")} className="cursor-span">
              Главная{" "}
            </span>{" "}
            -{" "}
            <span
              onClick={() => setRefreshd(!refreshd)}
              className="active-span"
            >
              Нейросети
            </span>
          </p>
          <p className="name__Page">Нейросети</p>
          <p></p>
        </div>
        <div className=" container section__filter2">
          <div className="filter__inner">
            <button
              onClick={() => filtrType("")}
              className={button === "" ? "btn__filter active" : "btn__filter"}
            >
              Всем
            </button>
            <button
              onClick={() => filtrType("Дизайнерам")}
              className={
                button === "Дизайнерам" ? "btn__filter active" : "btn__filter"
              }
            >
              Дизайнерам
            </button>
            <button
              onClick={() => filtrType("Разработчикам")}
              className={
                button === "Разработчикам"
                  ? "btn__filter active"
                  : "btn__filter"
              }
            >
              Разработчикам
            </button>
            <button
              onClick={() => filtrType("Копирайтерам")}
              className={
                button === "Копирайтерам" ? "btn__filter active" : "btn__filter"
              }
            >
              Копирайтерам
            </button>
            <button
              onClick={() => filtrType("Для бизнеса")}
              className={
                button === "Для бизнеса" ? "btn__filter active" : "btn__filter"
              }
            >
              Для бизнеса
            </button>
          </div>
        </div>
      </div>
      <div className="section__cartes">
        <div className="cartes__inner">
          <div className="seorch__cart">
            <div className="wrapper__seorch--input">
              <input
                value={search}
                onChange={(e) => handleChange(e)}
                className="seorch__cart--input"
                type="text"
                placeholder="Поиск нейросетей"
              />
              <img srcSet={seorchIcon} alt="" />
            </div>
            <div
              className={
                rotate
                  ? "wrapper__seorch--input slect-imgrotates"
                  : "wrapper__seorch--input "
              }
            >
              <select
                onBlur={() => {
                  setTimeout(() => {
                    setRotate(false);
                  }, [150]);
                }}
                onClick={() => setRotate(!rotate)}
                onChange={(e) => handleSelect(e)}
                className="tasks"
                name="tasks"
                id="tasks"
              >
                {!allSearchtype | !categoryGlobal && (
                  <option selected className="selected" hidden>
                    Задачи
                  </option>
                )}

                {zadacha.map((item, index) => (
                  <option
                    selected={item.id == zadachaid ? true : false}
                    value={item.id}
                    key={index}
                  >
                    {item?.title}
                  </option>
                ))}
              </select>
              <img srcSet={dropDownSelect} alt="" />
            </div>
            <div className="wrapper__chekBox">
              <div className="chekBox__inner">
                <input
                  className="cheked"
                  onChange={(e) => handleChacked(e)}
                  checked={freeid}
                  type="checkbox"
                  name=""
                  id=""
                />
                <p>Бесплатно</p>
              </div>
              <div className="chekBox__inner">
                <input
                  className="cheked"
                  checked={trail}
                  onChange={() => trailChange()}
                  type="checkbox"
                  name=""
                  id=""
                />
                <p>Триал</p>
              </div>
              <div className="chekBox__inner">
                <input
                  className="cheked"
                  checked={paid}
                  onChange={() => paidChange()}
                  type="checkbox"
                  name=""
                  id=""
                />
                <p>Платно</p>
              </div>
            </div>
          </div>
          <p id="tepa" onClick={() => getPopular()}>
            Самые популярные нейросети
          </p>
          <div className="container__lil">
            {reklama2.length === 0
              ? ""
              : reklama2.map((item, index) => (
                  <>
                    {item.image && (
                      <div className="addnewreklam">
                        <a target="_blan8" key={index} href={item.link}>
                          {item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "mp4" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "mov" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "avi" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "wmv" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "chd" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "ebm" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "flv" ? (
                            <video
                              src={item.image}
                              width="100%"
                              height="100%"
                              preload="none"
                              autoPlay
                              muted
                              loop
                            />
                          ) : (
                            <img
                              className="reklama__homeimg"
                              src={item.image}
                              alt=""
                            />
                          )}
                        </a>
                      </div>
                    )}
                    {item.type && (
                      <div
                        className="newbarnerreklama"
                        id={item.containerId}
                        dangerouslySetInnerHTML={{ __html: "" }}
                      />
                    )}
                  </>
                ))}
            <div className="wrapper__oll--cartes">
              {data?.map((item, index) => (
                <>
                  {item.adversiments && (
                    <div className="reklama__main">
                      <a
                        target={`_blank${item.id}`}
                        key={index}
                        href={item.link_adversiments}
                      >
                        {item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "mp4" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "mov" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "avi" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "wmv" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "chd" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "ebm" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "flv" ? (
                          <video
                            src={item.image_adversiments}
                            width="100%"
                            height="100%"
                            preload="none"
                            autoPlay
                            muted
                            loop
                          />
                        ) : (
                          <img
                            className="reklama__homeimg"
                            src={item.image_adversiments}
                            alt=""
                          />
                        )}
                      </a>
                    </div>
                  )}
                  {item.title && (
                    <div
                      onContextMenu={(e) => handleMenu(e, item?.slug)}
                      data-aos-duration="800"
                      data-aos="fade-down"
                      onClick={() => handeClick(item.slug)}
                      key={index}
                      className="carts__wrapper5"
                    >
                      <img srcSet={item.image} alt="" />

                      <h1 className="name__Ai">{item.title}</h1>
                      <span className="cart__context">{item.description}</span>
                      <div className="wrapper__bot">
                        {item.zadacha?.map((item, index) => (
                          <span key={index} className="cart__bot">
                            {item?.title}
                          </span>
                        ))}
                      </div>
                      <div className="wrapper__bot">
                        {item.icon_18 && (
                          <div className="image-container">
                            <img src={forAdults} alt="" />
                            <div class="hover-text">18+ </div>
                          </div>
                        )}
                        {item.icon_ru && (
                          <div className="image-container">
                            <img src={rus} alt="" />
                            <div class="hover-text">Российский</div>
                          </div>
                        )}
                        {item.free === true && (
                          <div className="image-container">
                            <img src={freee} alt="" />
                            <div class="hover-text">Бесплатно</div>
                          </div>
                        )}
                        {item.paid && (
                          <div className="image-container">
                            <img src={money} alt="" />
                            <div class="hover-text">Платно </div>
                          </div>
                        )}
                        {item.trial && (
                          <div className="image-container">
                            <img src={clock} alt="" />
                            <div class="hover-text">Триал</div>
                          </div>
                        )}
                        {item.vpn && (
                          <div className="image-container">
                            <img width={40} src={vpn} alt="" />
                            <div class="hover-text">Нужен VPN</div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {item.type && (
                    <div className="reklama__main">
                      <div
                        id={item.containerId}
                        dangerouslySetInnerHTML={{ __html: "" }}
                      />
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
          {paginate && (
            <div className="pagination__mid">
              {totalPage !== "" &&
                getPageNumbers(totalPage).map((page) => (
                  <a
                    href="#tepa"
                    onClick={() => changeActiveNum(page)}
                    className={activenum === page ? "active-page" : ""}
                  >
                    {page}
                  </a>
                ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
