import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import { useContext, useEffect, useState } from "react";
import http from "../../service/api";
import { Helmet } from "react-helmet-async";
import { Context } from "../../Context/Context";
export default function PromtyMidjourney() {
  const { seo } = useContext(Context);
  const [midjon, setMidjon] = useState([]);
  const [parametr, setParametr] = useState([]);
  const [struk, setStruk] = useState([]);
  const [padrob, setPadrob] = useState([]);
  const [other, setOther] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getMidjon();
    getParam();
    getStruk();
    getPadrob();
    getOtherCommand();
  }, []);
  const getMidjon = () => {
    http
      .get("/midjouney/midjouney/")
      .then((res) => {
        setMidjon(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getParam = () => {
    http
      .get("/midjouney/parametr/")
      .then((res) => {
        setParametr(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getStruk = () => {
    http
      .get("/midjouney/struktura/")
      .then((res) => {
        setStruk(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPadrob = () => {
    http
      .get("/midjouney/podrobno/")
      .then((res) => {
        setPadrob(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getOtherCommand = () => {
    http
      .get("/midjouney/other/command/")
      .then((res) => {
        setOther(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Helmet>
        <title>{seo?.ceo_midjourney}</title>
        <meta name="description" content={seo?.ceo_description_midjourney} />
      </Helmet>
      <div className="page__Promty--One">
        <div className="rang">
          <Header />
        </div>
        <div className="container">
          <p className="navigation__strel">
            {" "}
            <span onClick={() => navigate("/")} className="cursor-span">
              Главная
            </span>{" "}
            -{" "}
            <span onClick={() => navigate("/Promty")} className="cursor-span">
              Промты{" "}
            </span>
            - <span className="active-span"> Midjourney</span>
          </p>
          <p className="name__Page">Промты</p>
          <div className="wrapper__Ai-novigation">
            <Link to="/Promty">ChatGPT</Link>
            <Link to="/PromtyMidjourney">Midjourney</Link>
          </div>
        </div>
      </div>
      <section className="midjourney">
        <div className="container__mid">
          {midjon?.map((item, index) => (
            <div key={index}>
              {item.text_white && (
                <div
                  dangerouslySetInnerHTML={{ __html: item.text_white }}
                  className="midjourney__white"
                ></div>
              )}
              {item.zapros && (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item?.zapros }}
                  className="midjourney__black"
                ></div>
              )}
              {item?.otvet?.length !== 0 && (
                <div className="midjourney__img6">
                  {item.otvet.map((item, index) => (
                    <>
                      {item.type_16_9 ? (
                        <img className="img169" src={item.otvet} alt="" />
                      ) : (
                        <img src={item.otvet} alt="" />
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
          ))}
          <h3>Структура</h3>

          {struk?.map((item, index) => (
            <div key={index}>
              {item?.text_white && (
                <div
                  dangerouslySetInnerHTML={{ __html: item.text_white }}
                  className="midjourney__white"
                ></div>
              )}
              {item?.zapros && (
                <div
                  dangerouslySetInnerHTML={{ __html: item.zapros }}
                  className="midjourney__black"
                ></div>
              )}

              {item?.otvet?.length !== 0 && (
                <div className="midjourney__img6">
                  {item?.otvet.map((item, index) => (
                    <>
                      {item.type_16_9 ? (
                        <img className="img169" src={item.otvet} alt="" />
                      ) : (
                        <img src={item.otvet} alt="" />
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
          ))}
          <h3>Параметры</h3>

          {parametr?.map((item, index) => (
            <div key={index}>
              {item?.text_white && (
                <div
                  dangerouslySetInnerHTML={{ __html: item.text_white }}
                  className="midjourney__white"
                ></div>
              )}
              {item?.zapros && (
                <div
                  dangerouslySetInnerHTML={{ __html: item.zapros }}
                  className="midjourney__black"
                ></div>
              )}

              {item?.otvet?.length !== 0 && (
                <div className="midjourney__img6">
                  {item?.otvet.map((item, index) => (
                    <>
                      {item.type_16_9 ? (
                        <img className="img169" src={item.otvet} alt="" />
                      ) : (
                        <img src={item.otvet} alt="" />
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
          ))}

          <h3>Подробнее, как добавить вес слову</h3>
          {padrob?.map((item, index) => (
            <div key={index}>
              {item?.text_white && (
                <div
                  dangerouslySetInnerHTML={{ __html: item.text_white }}
                  className=" midjourney__white"
                ></div>
              )}
              {item?.otvet && (
                <div
                  dangerouslySetInnerHTML={{ __html: item.otvet }}
                  className="midjourney__black"
                ></div>
              )}
              {item?.image?.length !== 0 && (
                <div className="midjourney__img6">
                  {item.image.map((item, index) => (
                    <>
                      {item.type_16_9 ? (
                        <img className="img169" src={item.otvet} alt="" />
                      ) : (
                        <img src={item.otvet} alt="" />
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
          ))}

          <h3>Остальные команды:</h3>
          {other?.map((item, index) => (
            <div key={index}>
              {item?.text_white && (
                <div
                  dangerouslySetInnerHTML={{ __html: item.text_white }}
                  className="midjourney__white"
                />
              )}
              {item?.zapros && (
                <div
                  dangerouslySetInnerHTML={{ __html: item.zapros }}
                  className="midjourney__black "
                ></div>
              )}

              {item?.image?.length !== 0 && (
                <div className="midjourney__img6 ">
                  {item.image.map((item, index) => (
                    <>
                      {item.type_16_9 ? (
                        <img className="img169" src={item.otvet} alt="" />
                      ) : (
                        <img src={item.otvet} alt="" />
                      )}
                    </>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
}
