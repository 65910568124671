import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_API,
});
http.interceptors.request.use(
  (config) => {
    config.headers[
      "Authorization"
    ] = `Token e315f14ba51be698459c0e00d107623b534c2d1c`;
    return config;
  },
  (error) => Promise.reject(error)
);

export default http;
