import Header from "../Header";
import Footer from "../Footer";
import copy from "../../assets/img/svg/copy.svg";
import contat from "../../assets/img/svg/contact.svg";
import telegram from "../../assets/img/svg/teleg.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import http from "../../service/api";
import { Helmet } from "react-helmet-async";
import { Context } from "../../Context/Context";
const Generator = () => {
  const { text } = useParams();
  const { seo } = useContext(Context);
  const [data, setData] = useState({});
  const [data2, setData2] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [textru, setTextru] = useState("Копировать");
  const [texten, setTexten] = useState("Копировать");
  const [ru, setRu] = useState("");
  const [eng, setEng] = useState("");
  const [reklama2, setReklama2] = useState([]);
  const [refresh2, setRefresh2] = useState(false);
  const url = window.location.href;
  const navigate = useNavigate();
  useEffect(() => {
    getData();
    getData2();
    getReklama2();
  }, [text]);
  useEffect(() => {
    Aos.init();
  }, []);
  useEffect(() => {
    // Sayfanın yukarı kaydırılması
    window.scrollTo(0, 0);
  }, [text]);

  const getData = () => {
    http
      .get(`/promt/detail/${text}/`)
      .then((res) => {
        setData(res.data);
        setRu(res.data.description_ru);
        setEng(res.data.description_en);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getData2 = () => {
    http
      .get(`/promt/other/${text}/?limit=3 `)
      .then((res) => {
        setData2(res.data.results);
      })
      .catch((res) => {
        console.log(res);
      });
  };
  const handeleClick = (text) => {
    setRefresh(!refresh);
    navigate(`/genrator/${text}`);
  };
  const rusCopy = () => {
    navigator.clipboard
      .writeText(ru)
      .then((res) => {
        setTextru("Скопировано");
        setTexten("Копировать");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const engCopy = () => {
    navigator.clipboard
      .writeText(eng)
      .then((res) => {
        setTexten("Скопировано");
        setTextru("Копировать");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getReklama2 = () => {
    http
      .get("/promt/ad/detail/")
      .then((res) => {
        setReklama2(res.data.results);
        setRefresh2(!refresh2);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    function start() {
      if (window.YaMarketAffiliate) {
        for (let i = 0; i < reklama2.length; i++) {
          if (reklama2[i]?.type) {
            window.YaMarketAffiliate.createWidget({
              type: reklama2[i].type,
              containerId: reklama2[i].containerId,
              params: {
                clid: reklama2[i].clid - 0,
                erid: `${reklama2[i].erid}`,
                searchModelIds: [reklama2[i].searchModelIds - 0],
                themeId: reklama2[i].themeId - 0,
              },
            });
          }
        }
      }
    }

    if (window.YaMarketAffiliate) {
      start();
    } else {
      window.addEventListener("YaMarketAffiliateLoad", start);
    }
  }, [refresh2]);

  return (
    <div>
      <Helmet>
        <title>{seo?.ceo_promt}</title>
        <meta name="description" content={seo?.ceo_description_promt} />
      </Helmet>
      <div className="rang">
        <Header />
      </div>
      <section className="generator-seciton">
        <div className="container__hug">
          <div className="genarator__wrap">
            <h2>{data.title}</h2>
            <div className="generator__wrap-inner">
              <div className="gener-iner__iner">
                <h3>Русский</h3>
                <div className="gener-iner__text">
                  {data.description_ru?.split("\r\n").map((item, index) => (
                    <p>{item}</p>
                  ))}
                </div>
                <div onClick={() => rusCopy()} className="gener-iner__copy">
                  <img src={copy} alt="" />
                  <span>{textru}</span>
                </div>
              </div>
              <div className="gener-iner__iner">
                <h3>Английский</h3>
                <div className="gener-iner__text">
                  {data.description_en?.split("\r\n").map((item, index) => (
                    <p>{item}</p>
                  ))}
                </div>
                <div onClick={() => engCopy()} className="gener-iner__copy">
                  <img src={copy} alt="" />
                  <span>{texten}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="neyron-item__masange">
            <h3>Поделиться в соц.сетях</h3>
            <div className="neyron-item__masange-wrap">
              <a
                href={`https://vk.com/share.php?url=${url}&title=${data.title}`}
              >
                <img src={contat} alt="" />
              </a>
              <a
                href={`https://telegram.me/share/url?url=${url}&text=${data.title}`}
              >
                <img src={telegram} alt="" />
              </a>
            </div>
          </div>
          <div className="container__lil">
            {reklama2.length === 0
              ? ""
              : reklama2.map((item, index) => (
                  <>
                    {item.image && (
                      <div className="addnewreklam">
                        <a target="_blan6" key={index} href={item.link}>
                          {item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "mp4" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "mov" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "avi" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "wmv" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "chd" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "ebm" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "flv" ? (
                            <video
                              src={item.image}
                              width="100%"
                              height="100%"
                              preload="none"
                              autoPlay
                              muted
                              loop
                            />
                          ) : (
                            <img
                              className="reklama__homeimg"
                              src={item.image}
                              alt=""
                            />
                          )}
                        </a>
                      </div>
                    )}
                    {item.type && (
                      <div
                        className="newbarnerreklama"
                        id={item.containerId}
                        dangerouslySetInnerHTML={{ __html: "" }}
                      />
                    )}
                  </>
                ))}
          </div>

          <div className="drugiyi__promp">
            <h4>Другие промты</h4>

            <div className="wrapper__oll--cartes">
              {data2.map((item, index) => (
                <div
                  onClick={() => handeleClick(item.slug)}
                  key={index}
                  className="carts__wrapper5"
                >
                  <span className="cart__bot">{item.category?.title}</span>
                  <h1 className="name__Promts">{item.title}</h1>
                  <span className="cart__context3">{item.description_ru}</span>
                  <a className="bootom__cartGpt" href="#">
                    Подробнее
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Generator;
