import { useState, useEffect, useContext } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import telegramGroup from "../../assets/img/png/telgram group.png";
import http from "../../service/api";
import { Context } from "../../Context/Context";
import { Helmet } from "react-helmet-async";
export default function Advertising() {
  const { seo } = useContext(Context);
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    http
      .get("/advertisement/list/")
      .then((res) => {
        setData(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>{seo?.ceo_advers}</title>
        <meta name="description" content={seo?.ceo_description_advers} />
      </Helmet>
      <div className="page__reklama">
        <div className="rang">
          <Header />
        </div>
        <div className="container">
          <p className="navigation__strel">
            {" "}
            <span onClick={() => navigate("/")} className="cursor-span">
              Главная
            </span>{" "}
            - <span className="active-span"> Реклама</span>
          </p>
          <p className="name__Page">Реклама</p>
        </div>
      </div>
      <div className="wrapper__contact">
        <a href="https://t.me/ai_store1" className="wrapper__inner">
          <p className="education__text">
            Если у вас есть продукт или услуга, которую можно продвигать через
            рекламу
          </p>
          <p className="education__text--end">
            Напиши нам, и мы с удовольствием разместим на нашем сайте
          </p>

          <div className="bacgraund__telegram--inner2">
            <img src={telegramGroup} alt="" />
          </div>
        </a>
      </div>

      <div className="container__lil">
        <div className="wrapper__oll--cartes">
          {data.length === 0 ? (
            <div className="heightt"></div>
          ) : (
            data.map((item, index) => (
              <div key={index} className="carts__wrapper5">
                <img srcSet={item.image} alt="" />
                <h1 className="name__Ai">{item.title}</h1>
                <span className="cart__context">{item.description}</span>
              </div>
            ))
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
