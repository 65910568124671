import { useState, useEffect, useSyncExternalStore, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import seorchIcon from "../../assets/img/svg/seorchIcon.svg";
import dropDownSelect from "../../assets/img/svg/down-arrow-select.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import http from "../../service/api";
import { Helmet } from "react-helmet-async";
import { Context } from "../../Context/Context";
function Promty() {
  const { seo } = useContext(Context);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [reklama2, setReklama2] = useState([]);
  const navigate = useNavigate();
  const [rotate, setRotate] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refresh2, setRefresh2] = useState(false);
  const [refresh3, setRefresh3] = useState(false);
  const [totalPage, setTotalPage] = useState("");
  const [activenum, setActiveNum] = useState(1);
  const [search, setSearch] = useState("");
  const [categroyid, setCategoryid] = useState("");
  useEffect(() => {
    // Sayfanın yukarı kaydırılması
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getCategory();
    getReklama2();
  }, []);
  useEffect(() => {
    getData();
  }, [refresh]);
  useEffect(() => {
    Aos.init();
  }, []);
  const getData = () => {
    http
      .get(
        `/promt/list/?title=${search}&category_name=${categroyid}&limit=45&offset=${
          (activenum - 1) * 45
        }`
      )
      .then((res) => {
        setData(res.data.results);
        setTotalPage(Math.ceil(res.data.count / 45));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCategory = () => {
    http
      .get("/promt/category/")
      .then((res) => {
        setCategory(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handeleClick = (text) => {
    navigate(`/genrator/${text}`);
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
    setActiveNum(1);
    setRefresh(!refresh);
  };
  const handleSelect = (e) => {
    setCategoryid(e.target.value);
    setActiveNum(1);
    setRefresh(!refresh);
  };
  const getReklama2 = () => {
    http
      .get("/promt/banner/")
      .then((res) => {
        setReklama2(res.data.results);
        setRefresh2(!refresh2);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPageNumbers = (id) => {
    const pageNumbers = [];
    for (let i = 1; i <= id; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const handleActiveNum = (id) => {
    setActiveNum(id);
    setRefresh(!refresh);
  };

  return (
    <>
      <Helmet>
        <title>{seo?.ceo_promt}</title>
        <meta name="description" content={seo?.ceo_description_promt} />
      </Helmet>
      <div className="page__Promty--One">
        <div className="rang">
          <Header />
        </div>
        <div className="container">
          <p className="navigation__strel">
            {" "}
            <span onClick={() => navigate("/")} className="cursor-span">
              Главная
            </span>{" "}
            -{" "}
            <span onClick={() => navigate("/Promty")} className="cursor-span">
              Промты
            </span>{" "}
            - <span className="active-span"> ChatGPT</span>
          </p>
          <p className="name__Page">Промты</p>
          <div className="wrapper__Ai-novigation">
            <Link to="/Promty">ChatGPT</Link>
            <Link to="/PromtyMidjourney">Midjourney</Link>
          </div>
        </div>
      </div>
      {/* <GptCart articles={articles} loading={loading} /> */}
      <div className="seorch__cart">
        <div className="wrapper__seorch--input">
          <input
            onChange={(e) => handleChange(e)}
            className="seorch__cart--input"
            type="text"
            placeholder="Поиск промтов"
          />
          <img src={seorchIcon} alt="" />
        </div>
        <div
          id="tepa2"
          className={
            rotate
              ? "wrapper__seorch--input slect-imgrotates"
              : "wrapper__seorch--input "
          }
        >
          <select
            onClick={() => setRotate(!rotate)}
            onBlur={() => {
              setTimeout(() => {
                setRotate(false);
              }, [150]);
            }}
            onChange={(e) => handleSelect(e)}
            className="tasks"
            name="tasks"
            id="tasks"
          >
            <option selected className="selected" value=" ">
              категория
            </option>
            {category?.map((item, index) => (
              <option key={index} value={item.title}>
                {item.title}
              </option>
            ))}
          </select>
          <img src={dropDownSelect} alt="" />
        </div>
      </div>
      <div className="container__lil">
        {reklama2.length === 0
          ? ""
          : reklama2.map((item, index) => (
              <>
                {item.image && (
                  <div className="addnewreklam">
                    <a target="_blan8" key={index} href={item.link}>
                      {item.image.split("")[item.image.split("").length - 3] +
                        item.image.split("")[item.image.split("").length - 2] +
                        item.image.split("")[
                          item.image.split("").length - 1
                        ] ===
                        "mp4" ||
                      item.image.split("")[item.image.split("").length - 3] +
                        item.image.split("")[item.image.split("").length - 2] +
                        item.image.split("")[
                          item.image.split("").length - 1
                        ] ===
                        "mov" ||
                      item.image.split("")[item.image.split("").length - 3] +
                        item.image.split("")[item.image.split("").length - 2] +
                        item.image.split("")[
                          item.image.split("").length - 1
                        ] ===
                        "avi" ||
                      item.image.split("")[item.image.split("").length - 3] +
                        item.image.split("")[item.image.split("").length - 2] +
                        item.image.split("")[
                          item.image.split("").length - 1
                        ] ===
                        "wmv" ||
                      item.image.split("")[item.image.split("").length - 3] +
                        item.image.split("")[item.image.split("").length - 2] +
                        item.image.split("")[
                          item.image.split("").length - 1
                        ] ===
                        "chd" ||
                      item.image.split("")[item.image.split("").length - 3] +
                        item.image.split("")[item.image.split("").length - 2] +
                        item.image.split("")[
                          item.image.split("").length - 1
                        ] ===
                        "ebm" ||
                      item.image.split("")[item.image.split("").length - 3] +
                        item.image.split("")[item.image.split("").length - 2] +
                        item.image.split("")[
                          item.image.split("").length - 1
                        ] ===
                        "flv" ? (
                        <video
                          src={item.image}
                          width="100%"
                          height="100%"
                          preload="none"
                          autoPlay
                          muted
                          loop
                        />
                      ) : (
                        <img
                          className="reklama__homeimg"
                          src={item.image}
                          alt=""
                        />
                      )}
                    </a>
                  </div>
                )}
                {item.type && (
                  <div
                    data-aos-duration="800"
                    data-aos="fade-down"
                    className="newbarnerreklama"
                    id={item.containerId}
                    dangerouslySetInnerHTML={{ __html: "" }}
                  />
                )}
              </>
            ))}
        <div id="tepa2" className="wrapper__oll--cartes">
          {data.map((item, index) => (
            <>
              {item.adversiments && (
                <div className="reklama__main2">
                  <a
                    target={`_balne${item.id}`}
                    key={index}
                    href={item.link_adversiments}
                  >
                    {item.image_adversiments.split("")[
                      item.image_adversiments.split("").length - 3
                    ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 2
                      ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 1
                      ] ===
                      "mp4" ||
                    item.image_adversiments.split("")[
                      item.image_adversiments.split("").length - 3
                    ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 2
                      ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 1
                      ] ===
                      "mov" ||
                    item.image_adversiments.split("")[
                      item.image_adversiments.split("").length - 3
                    ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 2
                      ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 1
                      ] ===
                      "avi" ||
                    item.image_adversiments.split("")[
                      item.image_adversiments.split("").length - 3
                    ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 2
                      ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 1
                      ] ===
                      "wmv" ||
                    item.image_adversiments.split("")[
                      item.image_adversiments.split("").length - 3
                    ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 2
                      ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 1
                      ] ===
                      "chd" ||
                    item.image_adversiments.split("")[
                      item.image_adversiments.split("").length - 3
                    ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 2
                      ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 1
                      ] ===
                      "ebm" ||
                    item.image_adversiments.split("")[
                      item.image_adversiments.split("").length - 3
                    ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 2
                      ] +
                      item.image_adversiments.split("")[
                        item.image_adversiments.split("").length - 1
                      ] ===
                      "flv" ? (
                      <video
                        src={item.image_adversiments}
                        width="100%"
                        height="100%"
                        preload="none"
                        autoPlay
                        muted
                        loop
                      />
                    ) : (
                      <img
                        className="reklama__homeimg"
                        src={item.image_adversiments}
                        alt=""
                      />
                    )}
                  </a>
                </div>
              )}
              {item.title && (
                <div
                  data-aos-duration="800"
                  data-aos="fade-down"
                  onClick={() => handeleClick(item.slug)}
                  key={index}
                  className="carts__wrapper5"
                >
                  <span className="cart__bot">{item.category.title}</span>
                  <h1 className="name__Promts">{item.title}</h1>
                  <span className="cart__context3">{item.description_ru}</span>
                  <a className="bootom__cartGpt" href="#">
                    Подробнее
                  </a>
                </div>
              )}
              {item.type && (
                <div className="reklama__main">
                  <div
                    id={item.containerId}
                    dangerouslySetInnerHTML={{ __html: "" }}
                  />
                </div>
              )}
            </>
          ))}
        </div>
      </div>
      <div className="pagination__mid">
        {totalPage !== "" &&
          getPageNumbers(totalPage).map((page) => (
            <a
              href="#tepa2"
              onClick={() => handleActiveNum(page)}
              className={activenum === page ? "active-page" : ""}
            >
              {page}
            </a>
          ))}
      </div>

      <Footer />
    </>
  );
}

export default Promty;
