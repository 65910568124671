import { useState, useEffect, useContext } from "react";
import dropDown from "../../assets/img/svg/down-arrow.svg";
import Header from "../Header";
import Footer from "../Footer";
import homeImg from "../../assets/img/svg/down-arrow-cart.svg";
import seorchIcon from "../../assets/img/svg/seorchIcon.svg";
import dropDownSelect from "../../assets/img/svg/down-arrow-select.svg";
import forAdults from "../../assets/img/svg/18+.svg";
import rus from "../../assets/img/svg/ru.svg";
import clock from "../../assets/img/svg/time.svg";
import freee from "../../assets/img/svg/free.svg";
import money from "../../assets/img/svg/flag.svg";
import vpn from "../../assets/img/png/vpn.png";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context/Context";
import Aos from "aos";
import "aos/dist/aos.css";
import http from "../../service/api";
import { Helmet } from "react-helmet-async";

function HeaderSection() {
  const navigate = useNavigate();
  const {
    refful,
    setFiltername,
    setSearchtext,
    setAllSearchtype,
    setPaidGlobal,
    setTrialGlobal,
    setFreeGlobal,
    setCategoryGlobal,
  } = useContext(Context);
  const [button, setButto] = useState("");
  const [trail, setTrail] = useState(false);
  const [data, setData] = useState([]);
  const [free, setFree] = useState(true);
  const [paid, setPaid] = useState(false);
  const [zadacha, setZadacha] = useState([]);
  const [open, setOpen] = useState(false);
  const [reklama2, setReklama2] = useState([]);
  const [rotate, setRotate] = useState(false);
  const [refresh2, setRefresh2] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [zadachaid, setZadachaid] = useState("");
  const [freeid, setFreeid] = useState(false);
  const [trailid, setTrailId] = useState(false);
  const [activenum, setActiveNUm] = useState(1);
  const [refreshd, setRefreshd] = useState(false);
  const [seo, setSeo] = useState({});
  const handeClick = (text) => {
    navigate(`/Neytroitem/${text}`);
  };
  useEffect(() => {
    getData();
    getZadacha();
    getReklama2();
  }, [refreshd, refful]);
  useEffect(() => {
    Aos.init();
  }, []);
  const getData = () => {
    if (search.length > 0) {
      http
        .get(
          `/neauralnetwork/list/all?title=${search}&zadacha=${zadachaid}&free=${
            freeid ? freeid : ""
          }&trial=${trail === true ? trail : " "}&limit=45&offset=${
            (activenum - 1) * 45
          }&tag=${button === 0 ? "" : button}&paid=${paid ? paid : ""}`
        )
        .then((res) => {
          setData(res.data?.results);
          setRefresh(!refresh);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      http
        .get(
          `/neauralnetwork/list/main/all?title=${search}&zadacha=${zadachaid}&free=${
            freeid ? freeid : ""
          }&trial=${trail === true ? trail : " "}&limit=45&offset=${
            (activenum - 1) * 45
          }&tag=${button === 0 ? "" : button}&paid=${paid ? paid : ""}`
        )
        .then((res) => {
          setData(res.data);
          setRefresh(!refresh);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
    setSearchtext(e.target.value);
    setActiveNUm(1);

    if (!e.target.value) {
      setRefreshd(!refreshd);
    } else {
      http
        .get(
          `/neauralnetwork/list/all?title=${
            e.target.value
          }&zadacha=${zadachaid}&free=${freeid ? freeid : ""}&trial=${
            trail === true ? trail : " "
          }&limit=45&offset=${(activenum - 1) * 45}&tag=${
            button === 0 ? "" : button
          }&paid=${paid ? paid : ""}`
        )
        .then((res) => {
          setData(res.data?.results);
          setRefresh(!refresh);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getZadacha = () => {
    http
      .get("/neauralnetwork/list/zadacha/")
      .then((res) => {
        setZadacha(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSelect = (e) => {
    setActiveNUm(1);
    setZadachaid(e.target.value);
    setCategoryGlobal(e.target.value);
    setRefreshd(!refreshd);
  };
  const handleOpen = () => {
    setAllSearchtype(true);
    navigate("/neyron");
  };
  const freeChange = () => {
    setFree(!free);
    setFreeGlobal(!freeid);
    setActiveNUm(1);
    setFreeid(!freeid);
    setRefreshd(!refreshd);
  };
  const trailChange = () => {
    setTrail(!trail);
    setTrialGlobal(!trail);
    setActiveNUm(1);
    setTrailId(!trailid);
    setRefreshd(!refreshd);
  };
  const paidChange = () => {
    setPaid(!paid);
    setPaidGlobal(!paid);
    setActiveNUm(1);
    setTrailId(!paid);
    setRefreshd(!refreshd);
  };
  const filtrType = (id) => {
    setButto(id);
    setActiveNUm(1);
    setRefreshd(!refreshd);
  };
  const getPopular = () => {
    http
      .get(
        `/neauralnetwork/list/popular/?title=${search}&zadacha=${zadachaid}&free=${
          freeid ? freeid : ""
        }&trial=${trail === true ? trail : " "}&limit=45&offset=${
          (activenum - 1) * 45
        }&tag=${button === 0 ? "" : button}&paid=${paid ? paid : ""}`
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getReklama2 = () => {
    http
      .get("/neauralnetwork/adversiment/banner/neutral/")
      .then((res) => {
        setReklama2(res.data.results);
        setRefresh2(!refresh2);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSeodata = () => {
    http
      .get("/about/list/")
      .then((res) => {
        console.log(res.data);
        setSeo(res.data.at(0));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSeodata();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setFiltername("");
    setSearchtext("");
    setAllSearchtype(false);
    setPaidGlobal(false);
    setFreeGlobal(false);
    setCategoryGlobal("");
  }, []);
  const handleMenu = (e, link) => {
    e.preventDefault(); // Prevent the default right-click context menu
    window.open(`https://ai-store.pro/Neytroitem/${link}`, `_${link}`); // Redirect to the URL
  };
  return (
    <>
      <Helmet>
        <title>{seo?.ceo_title}</title>
        <meta name="description" content={seo?.ceo_description} />
      </Helmet>
      <div className="sectionHome">
        <Header />
        <div className="container section__inf">
          <div className="section__inner">
            <h1 className="section__inf--text">{seo?.title}</h1>
            <h3
              // dangerouslySetInnerHTML={{ __html: seo?.description }}
              className="section__inf--content"
            >
              {seo?.description}
            </h3>
          </div>
        </div>
        <div className=" container section__filter">
          <div className="filter__inner">
            <button
              onClick={() => {
                filtrType("");
                setFiltername("");
              }}
              className={button === "" ? "btn__filter active" : "btn__filter"}
            >
              Всем
            </button>
            <button
              onClick={() => {
                filtrType("Дизайнерам");
                setFiltername("Дизайнерам");
              }}
              className={
                button === "Дизайнерам" ? "btn__filter active" : "btn__filter"
              }
            >
              Дизайнерам
            </button>
            <button
              onClick={() => {
                filtrType("Разработчикам");
                setFiltername("Разработчикам");
              }}
              className={
                button === "Разработчикам"
                  ? "btn__filter active"
                  : "btn__filter"
              }
            >
              Разработчикам
            </button>
            <button
              onClick={() => {
                filtrType("Копирайтерам");
                setFiltername("Копирайтерам");
              }}
              className={
                button === "Копирайтерам" ? "btn__filter active" : "btn__filter"
              }
            >
              Копирайтерам
            </button>
            <button
              onClick={() => {
                filtrType("Для бизнеса");
                setFiltername("Для бизнеса");
              }}
              className={
                button === "Для бизнеса" ? "btn__filter active" : "btn__filter"
              }
            >
              Для бизнеса
            </button>
          </div>
          <a href="#id" className="dropDownBnt">
            <img className="" srcSet={dropDown} alt="" />
          </a>
        </div>
      </div>
      <div className="section__cartes">
        <div className="cartes__inner">
          <div className="seorch__cart">
            <div className="wrapper__seorch--input">
              <input
                onChange={(e) => handleChange(e)}
                className="seorch__cart--input"
                type="text"
                placeholder="Поиск нейросетей"
              />
              <img srcSet={seorchIcon} alt="" />
            </div>
            <div
              className={
                rotate
                  ? "wrapper__seorch--input slect-imgrotates"
                  : "wrapper__seorch--input "
              }
            >
              <select
                onClick={() => setRotate(!rotate)}
                onBlur={() => {
                  setTimeout(() => {
                    setRotate(false);
                  }, [150]);
                }}
                onChange={(e) => handleSelect(e)}
                className="tasks"
                name="tasks"
                id="tasks"
              >
                <option selected className="selected" value="">
                  Задачи
                </option>
                {zadacha.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.title}
                  </option>
                ))}
              </select>
              <img srcSet={dropDownSelect} alt="" />
            </div>
            <div id="id" className="wrapper__chekBox">
              <div className="chekBox__inner">
                <input
                  className="cheked"
                  onChange={() => freeChange()}
                  type="checkbox"
                  name=""
                  id=""
                />
                <p>Бесплатно</p>
              </div>
              <div className="chekBox__inner">
                <input
                  className="cheked"
                  onChange={() => trailChange()}
                  type="checkbox"
                  name=""
                  id=""
                />
                <p>Триал</p>
              </div>
              <div className="chekBox__inner">
                <input
                  className="cheked"
                  onChange={() => paidChange()}
                  type="checkbox"
                  name=""
                  id=""
                />
                <p>Платно</p>
              </div>
            </div>
          </div>
          <p onClick={() => getPopular()}>Самые популярные нейросети</p>
          <div className="container__lil">
            {reklama2.length === 0
              ? ""
              : reklama2.map((item, index) => (
                  <>
                    {item.image && (
                      <div className="addnewreklam">
                        <a target="_blan8" key={index} href={item.link}>
                          {item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "mp4" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "mov" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "avi" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "wmv" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "chd" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "ebm" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "flv" ? (
                            <video
                              src={item.image}
                              width="100%"
                              height="100%"
                              preload="none"
                              autoPlay
                              muted
                              loop
                            />
                          ) : (
                            <img
                              className="reklama__homeimg"
                              src={item.image}
                              alt=""
                            />
                          )}
                        </a>
                      </div>
                    )}
                    {item.type && (
                      <div
                        className="newbarnerreklama"
                        id={item.containerId}
                        dangerouslySetInnerHTML={{ __html: "" }}
                      />
                    )}
                  </>
                ))}
            <div></div>
            <div className="wrapper__oll--cartes">
              {data?.map((item, index) => (
                <>
                  {item.adversiments && (
                    <div className="reklama__main">
                      <a
                        target={`_blank${item.id}`}
                        key={index}
                        href={item.link_adversiments}
                      >
                        {item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "mp4" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "mov" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "avi" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "wmv" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "chd" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "ebm" ||
                        item.image_adversiments.split("")[
                          item.image_adversiments.split("").length - 3
                        ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 2
                          ] +
                          item.image_adversiments.split("")[
                            item.image_adversiments.split("").length - 1
                          ] ===
                          "flv" ? (
                          <video
                            src={item.image_adversiments}
                            width="100%"
                            height="100%"
                            preload="none"
                            autoPlay
                            muted
                            loop
                          />
                        ) : (
                          <img
                            className="reklama__homeimg"
                            src={item.image_adversiments}
                            alt=""
                          />
                        )}
                      </a>
                    </div>
                  )}
                  {item.title && (
                    <div
                      onContextMenu={(e) => handleMenu(e, item?.slug)}
                      data-aos-duration="800"
                      data-aos="fade-down"
                      onClick={() => handeClick(item.slug)}
                      key={index}
                      className="carts__wrapper5"
                    >
                      <img srcSet={item.image} alt="" />

                      <h1 className="name__Ai">{item.title}</h1>
                      <span className="cart__context">{item.description}</span>
                      <div className="wrapper__bot">
                        {item.zadacha?.map((item, index) => (
                          <span key={index} className="cart__bot">
                            {item?.title}
                          </span>
                        ))}
                      </div>
                      <div className="wrapper__bot">
                        {item.icon_18 && (
                          <div className="image-container">
                            <img src={forAdults} alt="" />
                            <div class="hover-text">18+ </div>
                          </div>
                        )}
                        {item.icon_ru && (
                          <div className="image-container">
                            <img src={rus} alt="" />
                            <div class="hover-text">Российский</div>
                          </div>
                        )}
                        {item.free === true && (
                          <div className="image-container">
                            <img src={freee} alt="" />
                            <div class="hover-text">Бесплатно</div>
                          </div>
                        )}
                        {item.paid && (
                          <div className="image-container">
                            <img src={money} alt="" />
                            <div class="hover-text">Платно </div>
                          </div>
                        )}
                        {item.trial && (
                          <div className="image-container">
                            <img src={clock} alt="" />
                            <div class="hover-text">Триал</div>
                          </div>
                        )}
                        {item.vpn && (
                          <div className="image-container">
                            <img width={40} src={vpn} alt="" />
                            <div class="hover-text">Нужен VPN </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {item.type && (
                    <div className="reklama__main">
                      <div
                        id={item.containerId}
                        dangerouslySetInnerHTML={{ __html: "" }}
                      />
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => handleOpen()} className="wrapper__showOll--carts">
        <img className={open ? "trans" : ""} src={homeImg} alt="" />
        <button className="show__oll--cats">
          {open ? "Вверх" : "Смотреть все"}
        </button>
        <img className={open ? "trans" : ""} src={homeImg} alt="" />
      </div>
      <Footer />
    </>
  );
}

export default HeaderSection;
