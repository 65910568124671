import { createContext, useEffect, useState } from "react";
import http from "../service/api";
const Context = createContext();
const ContextProvider = ({ children }) => {
  const [refful, setReffull] = useState(false);
  const [fitlername, setFiltername] = useState("");
  const [searchtext, setSearchtext] = useState("");
  const [allSearchtype, setAllSearchtype] = useState(false);
  const [seo, setSeo] = useState({});
  const [paidGlobal, setPaidGlobal] = useState(false);
  const [trialGlobal, setTrialGlobal] = useState(false);
  const [freeGlobal, setFreeGlobal] = useState(false);
  const [categoryGlobal, setCategoryGlobal] = useState("");
  const getSeo = () => {
    http
      .get("/about/all-seo/")
      .then((res) => {
        setSeo(res.data?.results?.at(0));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSeo();
  }, []);
  return (
    <Context.Provider
      value={{
        paidGlobal,
        setPaidGlobal,
        trialGlobal,
        setTrialGlobal,
        freeGlobal,
        setFreeGlobal,
        refful,
        setReffull,
        fitlername,
        setFiltername,
        searchtext,
        setSearchtext,
        allSearchtype,
        setAllSearchtype,
        seo,
        categoryGlobal,
        setCategoryGlobal,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export { Context, ContextProvider };
