import Header from "../Header";
import Footer from "../Footer";
import contat from "../../assets/img/svg/contact.svg";
import telegram from "../../assets/img/svg/teleg.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import forAdults from "../../assets/img/svg/18+.svg";
import rus from "../../assets/img/svg/ru.svg";
import clock from "../../assets/img/svg/time.svg";
import free from "../../assets/img/svg/free.svg";
import money from "../../assets/img/svg/flag.svg";
import vpn from "../../assets/img/png/vpn.png";
import http from "../../service/api";
import { Helmet } from "react-helmet-async";
import { Context } from "../../Context/Context";

const Neyroitem = () => {
  const { text } = useParams();
  const { seo } = useContext(Context);
  const [data, setData] = useState({});
  const [data2, setData2] = useState([]);
  const [reklama, setReklama] = useState([]);
  const [refresh2, setRefresh2] = useState(false);
  const url = window.location.href;
  const text2 = window.location.href.split("/")[4];
  const navigate = useNavigate();
  useEffect(() => {
    getData();
    getData2();
    getReklam();
  }, [text]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [text]);

  const getData = () => {
    http
      .get(`/neauralnetwork/detail/${text2}/`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getData2 = () => {
    http
      .get(`/neauralnetwork/list/similar/${text2}/?limit=3`)
      .then((res) => {
        setData2(res.data.results);
      })
      .catch((res) => {
        console.log(res);
      });
  };
  const handeClick = (text) => {
    navigate(`/Neytroitem/${text}`);
  };
  const getReklam = () => {
    http
      .get("/neauralnetwork/adversiment/detail/")
      .then((res) => {
        setReklama(res.data.results);
        setRefresh2(!refresh2);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleMenu = (e, link) => {
    e.preventDefault();
    window.open(`https://ai-store.pro/Neytroitem/${link}`, `_${link}`); // Redirect to the URL
  };

  return (
    <div>
      <Helmet>
        <title>{seo?.ceo_title_neural}</title>
        <meta name="description" content={seo?.ceo_description_neural} />
      </Helmet>
      <div className="rang">
        <Header />
      </div>
      <section className="neyroitem-section">
        <div className="container__hug2">
          <div className="neyronitem__wrap">
            <div className="neyronitem__wrap-item">
              <div className="neyronitem__wrap-item1">
                <h2>{data.title}</h2>
                <img src={data.image} alt="" />
                <div className="neyronitem__btns">
                  {data.zadacha?.map((item, index) => (
                    <span key={index} className="cart__bot">
                      {item?.title}
                    </span>
                  ))}
                </div>
                <p>
                  {data.description?.split("\r\n").map((item, index) => (
                    <p>{item}</p>
                  ))}
                </p>
                <div className="neyronitem__footer-btns">
                  <a target="_blankkk" href={data.link}>
                    Перейти на сайт
                  </a>
                  {data.icon_18 && (
                    <div className="image-container">
                      <img src={forAdults} alt="" />
                      <div class="hover-text">18+ </div>
                    </div>
                  )}
                  {data.icon_ru && (
                    <div className="image-container">
                      <img src={rus} alt="" />
                      <div class="hover-text">Российский</div>
                    </div>
                  )}
                  {data.free === true && (
                    <div className="image-container">
                      <img src={free} alt="" />
                      <div class="hover-text">Бесплатно</div>
                    </div>
                  )}
                  {data.paid && (
                    <div className="image-container">
                      <img src={money} alt="" />
                      <div class="hover-text">Платно </div>
                    </div>
                  )}
                  {data.trial && (
                    <div className="image-container">
                      <img src={clock} alt="" />
                      <div class="hover-text">Триал</div>
                    </div>
                  )}
                  {data.vpn && (
                    <div className="image-container">
                      <img width={40} src={vpn} alt="" />
                      <div class="hover-text">Нужен VPN</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="neyronitem__reklamam">
                {reklama.length === 0 ? (
                  <h3>здесь могла быть ваша реклама</h3>
                ) : (
                  reklama.map((item, index) => (
                    <>
                      {item.image && (
                        <a href={item.link} target="_blanf" key={index}>
                          <div key={index}>
                            {item.image.split("")[
                              item.image.split("").length - 3
                            ] +
                              item.image.split("")[
                                item.image.split("").length - 2
                              ] +
                              item.image.split("")[
                                item.image.split("").length - 1
                              ] ===
                              "mp4" ||
                            item.image.split("")[
                              item.image.split("").length - 3
                            ] +
                              item.image.split("")[
                                item.image.split("").length - 2
                              ] +
                              item.image.split("")[
                                item.image.split("").length - 1
                              ] ===
                              "mov" ||
                            item.image.split("")[
                              item.image.split("").length - 3
                            ] +
                              item.image.split("")[
                                item.image.split("").length - 2
                              ] +
                              item.image.split("")[
                                item.image.split("").length - 1
                              ] ===
                              "avi" ||
                            item.image.split("")[
                              item.image.split("").length - 3
                            ] +
                              item.image.split("")[
                                item.image.split("").length - 2
                              ] +
                              item.image.split("")[
                                item.image.split("").length - 1
                              ] ===
                              "wmv" ||
                            item.image.split("")[
                              item.image.split("").length - 3
                            ] +
                              item.image.split("")[
                                item.image.split("").length - 2
                              ] +
                              item.image.split("")[
                                item.image.split("").length - 1
                              ] ===
                              "chd" ||
                            item.image.split("")[
                              item.image.split("").length - 3
                            ] +
                              item.image.split("")[
                                item.image.split("").length - 2
                              ] +
                              item.image.split("")[
                                item.image.split("").length - 1
                              ] ===
                              "ebm" ||
                            item.image.split("")[
                              item.image.split("").length - 3
                            ] +
                              item.image.split("")[
                                item.image.split("").length - 2
                              ] +
                              item.image.split("")[
                                item.image.split("").length - 1
                              ] ===
                              "flv" ? (
                              <video
                                src={item.image}
                                width="100%"
                                height="100%"
                                preload="none"
                                autoPlay
                                muted
                                loop
                              />
                            ) : (
                              <img
                                className="neyro__reklamaimg"
                                src={item.image}
                                alt=""
                              />
                            )}
                          </div>
                        </a>
                      )}
                      {item.type && (
                        <div
                          id={item.containerId}
                          dangerouslySetInnerHTML={{ __html: "" }}
                        />
                      )}
                    </>
                  ))
                )}
              </div>
            </div>
            <div className="neyron-item__masange">
              <h3>Поделиться в соц.сетях</h3>
              <div className="neyron-item__masange-wrap">
                <a
                  href={`https://vk.com/share.php?url=${url}&title=${data.title}`}
                >
                  <img src={contat} alt="" />
                </a>
                <a
                  href={`https://telegram.me/share/url?url=${url}&text=${data.title}`}
                >
                  <img src={telegram} alt="" />
                </a>
              </div>
            </div>
            <div className="neyron-item__masangerek">
              {reklama.length === 0 ? (
                <h3>здесь могла быть ваша реклама</h3>
              ) : (
                reklama.map((item, index) => (
                  <>
                    {item.image && (
                      <a href={item.link} target="_blanf" key={index}>
                        <div key={index}>
                          {item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "mp4" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "mov" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "avi" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "wmv" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "chd" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "ebm" ||
                          item.image.split("")[
                            item.image.split("").length - 3
                          ] +
                            item.image.split("")[
                              item.image.split("").length - 2
                            ] +
                            item.image.split("")[
                              item.image.split("").length - 1
                            ] ===
                            "flv" ? (
                            <video
                              src={item.image}
                              width="100%"
                              height="100%"
                              preload="none"
                              autoPlay
                              muted
                              loop
                            />
                          ) : (
                            <img
                              className="neyro__reklamaimg"
                              src={item.image}
                              alt=""
                            />
                          )}
                        </div>
                      </a>
                    )}
                    {item.type && (
                      <div
                        id={item.containerId}
                        dangerouslySetInnerHTML={{ __html: "" }}
                      />
                    )}
                  </>
                ))
              )}
            </div>
            <div className="neyron-item__nerons">
              <h4>Похожие нейросети:</h4>
              <div className="wrapper__oll--cartes">
                {data2.map((item, index) => (
                  <div
                    onContextMenu={(e) => handleMenu(e, item?.slug)}
                    onClick={() => handeClick(item.slug)}
                    key={index}
                    className="carts__wrapper5"
                  >
                    <img srcSet={item.image} alt="" />
                    <h1 className="name__Ai">{item.title}</h1>
                    <span className="cart__context">{item.description}</span>
                    <div className="wrapper__bot">
                      {item.zadacha?.map((item, index) => (
                        <span key={index} className="cart__bot">
                          {item?.title}
                        </span>
                      ))}
                    </div>
                    <div className="wrapper__bot">
                      {item.icon_18 && (
                        <div className="image-container">
                          <img src={forAdults} alt="" />
                          <div class="hover-text">18+ </div>
                        </div>
                      )}
                      {item.icon_ru && (
                        <div className="image-container">
                          <img src={rus} alt="" />
                          <div class="hover-text">Русский язык</div>
                        </div>
                      )}
                      {item.free === true && (
                        <div className="image-container">
                          <img src={free} alt="" />
                          <div class="hover-text">Бесплатно</div>
                        </div>
                      )}
                      {item.paid && (
                        <div className="image-container">
                          <img src={money} alt="" />
                          <div class="hover-text">Платно </div>
                        </div>
                      )}
                      {item.trial && (
                        <div className="image-container">
                          <img src={clock} alt="" />
                          <div class="hover-text">Триал</div>
                        </div>
                      )}
                      {item.vpn && (
                        <div className="image-container">
                          <img width={40} src={vpn} alt="" />
                          <div class="hover-text">Нужен VPN</div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Neyroitem;
